import axios from 'axios'
import Toastify from "toastify-js";

import qs from 'qs'
import Cookies from "js-cookie";

import Router from '@/router'

// const token = false


// create an axios instance
const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
	timeout: 15000, // request timeout
	withCredentials: true,
})



// request interceptor
service.interceptors.request.use(

	config => {
 
		if (Cookies.get("accessToken")) {
			let accessToken =  Cookies.get("accessToken")


			config.headers['Authorization'] = 'Bearer ' + accessToken
		}
		return config
	},
	response => {
		console.log(response.status, 'response')
	},
	error => {
		// Do something with request error
		console.log("出错啦", error) // for debug
	}
)

// response interceptor
service.interceptors.response.use(response => {

	if (response.status != 200) {
		console.log(response, 'response2')
	}
	return response.data
},
	error => {


		if (error.response.status === 401) {
			// 账号无权限
			if (error.response.data.code === 40104) {
				// 回到登陆页面
				Router.push('/login')
				// 删除userInfo Cookies
				Cookies.remove("accessToken");
			}
		}

		// // 登陆失效 403
		if ((error.response.status === 400 && error.response.data.code === 400 && error.response.data.msg === 'token不能为空') || error.response.status === 403) {
			console.log('登陆失效xxxxx')
			// 回到登陆页面
			Router.push('/login')
			// 删除userInfo Cookies
			Cookies.remove("accessToken");
		}



		// console.log(error.respons)
		if (error.response.status !== 200 && error.response.status !== 401) {

			console.log(error.response.status, 'response')
		}

		return Promise.reject(error.response)
	}
)



export default function request(param) {
	let { url, method, data } = param

	if (method !== 'POST' && method !== 'post') {
		url = url + '?' + qs.stringify(data)
	}

	return new Promise((resolve) => {
		service({
			url: url,
			method: method || 'GET',
			data: data
		}).then((result) => {
			if (result.code === 0) {
				//  成功
				resolve(result)
			} else {
				console.error('未知错误:' + result)


				if (!Cookies.get("userInfo")) {

					Router.push('/login')

					Toastify({
						node: cash(`<span style='color:#D32929'>登陆失效</span>`)[0],
						duration: 3000,
						newWindow: true,
						close: false,
						stopOnFocus: true,
						backgroundColor: '#ffd8d8'
					}).showToast();
				}



			}
		}).catch((err) => {
			console.log(err)
			Toastify({
				node: cash(`<span style='color:#D32929'>${err.data.msg}</span>`)[0],
				duration: 3000,
				newWindow: true,
				close: false,
				stopOnFocus: true,
				backgroundColor: '#ffd8d8'
			}).showToast();
		});
	})

}





// export default service
